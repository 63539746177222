import React from "react";
import {Link} from "gatsby";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/PageWrapper";
import VideoDemo from "../sections/common/VideoDemoNew";

import CTA from "../sections/CTA/CTA";
import Wave from "../sections/common/Wave";

import SEO from '../components/SEO';
import imgC from "../assets/image/metaimg/showcase.jpg";

const Demo = () => {
  return (  
    <>
   
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Stuart Verschoyle, Founder and CEO of Digma"
          description="Founder and CEO of Digma"
          image={imgC}
        />    
        <Helmet>        
        <link
          href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=stuart'}
          rel="stylesheet"
          />
        </Helmet>                 
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/stuart-verschoyle">Stuart Verschoyle</Link>
              </li>
              </ol>
           </nav>
        </div>  
        <Wave pos="top" color="#0c426d" />
        <VideoDemo className="bg-digma-dark" title="Stuart Verschoyle" name="Founder &amp; Ceo" projectId="5807F96C-3846-4530-99C9-1B1EC66D4D7D" />
        <CTA className="bg-images" />

      </PageWrapper>
    </>
  );
};
export default Demo;
